import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from '../../sections/footer'
import Cookies from 'universal-cookie';
import SideBar from '../../components/sidebar';
import { Grid } from '@mui/material';
import useViewport from '../../hooks/useViewport';

function UserLayout({ user }) {
    const cookie = new Cookies();
    const { width } = useViewport();

    return (
        cookie.get('token') ?
            <>
                <Navbar user={user} />
                <Grid container fluid >
                    {
                        width > 900 ?
                            <Grid item lg={2} md={2} style={{ padding: 0 }}>
                                <SideBar />
                            </Grid>
                            :
                            null
                    }

                    <Grid item lg={10} md={10} sm={12} xs={12} style={{ padding: 0 }}>
                        <Outlet />
                    </Grid>
                </Grid>

                <Footer />
            </>
            :
            <>
                <Navigate to="/" replace={true} />
            </>
    )
}

export default UserLayout