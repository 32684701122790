import React from 'react'
// import { Container, Grid } from '@mui/material';
// import Navbar from '../../components/navbar';
import './styles.css'
// import Buttons from '../../components/buttons';
import CryptoCurrency from '../../components/cryptocurrency';
import MobileApp from '../../sections/mobileapp';
import Features from '../../sections/features';
import Blogs from '../../sections/blog';
import Body from '../../sections/body';
import Benefits from '../../sections/benefits';

function HomePage({ coins, user }) {

    return (
        <div>
            <Body coins={coins} user={user} />
            <CryptoCurrency coins={coins} />
            <MobileApp />
            <Features />
            <Blogs />
            <Benefits />
        </div>
    )
}

export default HomePage