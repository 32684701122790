import './App.css';
import HomePage from './pages/homepage';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Signin from './pages/signin';
import Signup from './pages/signup';
import { useEffect, useState } from 'react';
import Profile from './pages/profile';
import Markets from './pages/markets';
import AuthLayout from './layout/authlayout';
import PublicLayout from './layout/publiclayout';
import UserLayout from './layout/userlayout';
import { getCoins, getUserById } from './api';
import WithDraw from './pages/withdraw';
import Deposite from './pages/deposite';
import Cookies from 'universal-cookie';
import Transactions from './pages/transactions';


function App() {
  const [coins, setCoins] = useState([])
  const cookie = new Cookies()
  const [user, setUser] = useState([])


  useEffect(
    () => {
      getCoins().then(res => {
        setCoins(res)
      }).catch(error => console.log(error));

      cookie.get('id') &&
        getUserById(cookie.get('id')).then(res => {
          setUser(res.data)
        }).catch(error => console.log(error));
    },
    [])


  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<PublicLayout user={user} />}>
            <Route path="/" element={<HomePage coins={coins} user={user} />} />
            {
              !cookie.get('token') ?
                <Route path="/markets" element={<Markets coins={coins} />} />
                :
                null
            }
          </Route>

          <Route path='/' element={<AuthLayout user={user} />}>
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<Signup />} />
          </Route>

          <Route path='/' element={<UserLayout user={user} />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/withdraw" element={<WithDraw user={user} />} />
            <Route path="/deposit" element={<Deposite />} />
            {
              cookie.get('token') ?
                <Route path="/markets" element={<Markets coins={coins} />} />
                :
                null
            }
            <Route path="/transactions" element={<Transactions />} />
          </Route>
        </Routes>
      </Router>

    </>
  );
}

export default App;
