import { Box, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import './styles.css'
import { useFormik } from 'formik';
import { register } from '../../api';
// import { useNavigate } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MuiPhoneNumber from 'material-ui-phone-number';
// import moment from 'moment';

function SignupFields({ setErrorMessage }) {
    const [startDate, setStartDate] = useState(new Date());
    // const BD='DD/MM/YYY';
    // const formatDate = moment(startDate).format()
    const [phoneValue, setPhoneValue] = useState()
    const [confirmPass, setConfirmPass] = useState('')
    const [sub, setSub] = useState(false);
    // const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            wallet: '',
            username: '',
            password: '',
            birthdate: startDate,
        },
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                username: formik.values.username,
                password: formik.values.password,
                wallet: formik.values.wallet,
                birthdate: startDate,
                phone: phoneValue,
            }

            setSub(true);

            const func = register(data);

            func.then(async (response) => {

                if (document.getElementById('firstname-required').value === ''
                    || document.getElementById('lastname-required').value === ''
                    || document.getElementById('username-required').value === ''
                    || document.getElementById('email-required').value === ''
                    || document.getElementById('password').value === ''
                    || document.getElementById('confirm-password').value === '') {
                    setErrorMessage('Uncomplete Sign up');
                }

                else if (formik.values.password !== confirmPass) {
                    setConfirmPass('');
                    setErrorMessage('Confirm your password')
                }

                else if (response.success) {
                    setErrorMessage('Account successfully created.')
                }

                else {
                    setErrorMessage('User already taken.')
                }
            })

        }
    })


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box className='boxes'>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.firstname || ''}
                        fullWidth
                        id="firstname-required"
                        label="First Name"
                        variant="outlined"
                        margin="none"
                        name="firstname"
                        error={sub === true && document.getElementById('firstname-required').value === ''}

                    />
                    <TextField
                        style={{ marginLeft: 16 }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.lastname || ''}
                        fullWidth
                        id="lastname-required"
                        label="Last Name"
                        variant="outlined"
                        margin="none"
                        name="lastname"
                        error={sub === true && document.getElementById('lastname-required').value === ''}
                    />
                </Box>

                <Box className='boxes'>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.username || ''}
                        fullWidth
                        type="text"
                        id="username-required"
                        label="Username"
                        variant="outlined"
                        margin="none"
                        name="username"
                        error={sub === true && document.getElementById('username-required').value === ''}
                    />
                    <TextField
                        style={{ marginLeft: 16 }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email || ''}
                        type="email"
                        fullWidth
                        id="email-required"
                        label="Email"
                        variant="outlined"
                        margin="none"
                        name="email"
                        error={sub === true && document.getElementById('email-required').value === ''}
                    />
                </Box>

                <Box className='boxes'>
                    <div className="phoneNumberContainer">
                        <MuiPhoneNumber
                            className='phoneNumber'
                            defaultCountry={'us'}
                            onChange={setPhoneValue}
                            value={phoneValue}
                            name="phone"
                            id="phone-required"
                        />
                    </div>
                    <div style={{ marginLeft: 16, width: '100%' }}>
                        <DatePicker
                            className='date'
                            selected={startDate}
                            name='birthdate'
                            onChange={(date) => setStartDate(date)}
                            value={startDate}
                            dateFormat="dd/mm/yyyy"
                            id="date-required"
                        />
                    </div>
                </Box>

                <Box className='boxes' >
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password || ''}
                        type="password"
                        fullWidth
                        id="password"
                        label="Password"
                        variant="outlined"
                        margin="none"
                        name="password"
                        error={sub === true && document.getElementById('password').value === ''}
                    />
                </Box>

                <Box className='boxes' >
                    <TextField
                        onChange={(e) => setConfirmPass(e.target.value)}

                        value={confirmPass}
                        type="password"
                        fullWidth
                        id="confirm-password"
                        label="Confirm Password"
                        variant="outlined"
                        margin="none"
                        name="confirmPassword"
                        error={sub === true && document.getElementById('confirm-password').value === ''}
                    />
                </Box>

                <Box style={{ marginTop: 32 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        className='submitButton'
                    >
                        Sign up
                    </Button>
                </Box>

            </form>
        </>
    )
}

export default SignupFields