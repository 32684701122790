import React from 'react'
import './styles.css'
import { Container, Grid } from '@mui/material'
import { BsAlarm, BsBarChartLineFill } from 'react-icons/bs'
import { FaUserCircle } from 'react-icons/fa'
import { RiMoneyDollarCircleFill, RiAlertFill, RiSecurePaymentLine } from 'react-icons/ri'
import { MdTouchApp, MdOutlineMonetizationOn } from 'react-icons/md'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { BiTransferAlt, BiMoney, BiWorld } from 'react-icons/bi'

function Benefits() {

    const benifits = [
        {
            icon: <BsAlarm color='#004FF3' size={35} />,
            title: 'Get started in 30 seconds',
        },
        {
            icon: <FaUserCircle color='#004FF3' size={35} />,
            title: 'Two rewards accounts',
        },
        {
            icon: <RiMoneyDollarCircleFill color='#004FF3' size={35} />,
            title: 'Daily rewards',
        },
        {
            icon: <BiTransferAlt color='#004FF3' size={35} />,
            title: 'Transfer daily rewards',
        },
        {
            icon: <BiMoney color='#004FF3' size={35} />,
            title: 'Instant withdraw',
        },
        {
            icon: <MdTouchApp color='#004FF3' size={35} />,
            title: 'Generous referral incentives',
        },
        {
            icon: <FaFileInvoiceDollar color='#004FF3' size={35} />,
            title: 'Infinity matching bonuses',
        },
        {
            icon: <BsBarChartLineFill color='#004FF3' size={35} />,
            title: 'Full transparency',
        },
        {
            icon: <MdOutlineMonetizationOn color='#004FF3' size={35} />,
            title: 'No membership fees',
        },
        {
            icon: <RiSecurePaymentLine color='#004FF3' size={35} />,
            title: 'State-of-the-art security',
        },
        {
            icon: <RiAlertFill color='#004FF3' size={35} />,
            title: 'Fully comliant',
        },
        {
            icon: <BiWorld color='#004FF3' size={35} />,
            title: 'Worldwide',
        },

    ]

    return (
        <div className='benifitsWrapper'>
            <Container>
                <Grid container fluid >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="benifitsTitle">
                        <p>Benifits</p>
                    </Grid>
                    <Grid container fluid>
                        {
                            benifits.map((benifit) => (
                                <Grid xl={3} lg={3} md={4} sm={6} xs={12}>
                                    <div className='benifitContainer'>
                                        <div>
                                            {benifit.icon}
                                        </div>
                                        <div className='benifitTitle'>
                                            <p style={{ margin: 0 }}>{benifit.title}</p>
                                        </div>
                                    </div>
                                </Grid>
                            ))
                        }

                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Benefits