import { Button, TextField, Box } from '@mui/material'
import React from 'react'
import './styles.css'
import { useFormik } from 'formik'
import { ContactUs } from '../../api'
import Snackbars from '../snackbar'
import { useState } from 'react'

function ContactUsFields() {
    const [successopen, setSuccessOpen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };
    const formik = useFormik({
        initialValues: {
            email: "",
            message: "",
        },
        onSubmit: () => {
            const email = formik.values.email
            const message = formik.values.message
            const contactfunc = ContactUs(email, message);
            contactfunc.then(async (response) => {
                if (response.success) {
                    setSuccessOpen(true)
                    window.location.reload()
                }
            });
        }
    })

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className='textContact'>We are waiting to hear from you in case of any problem</p>
                <p className='emailTo'>To: Support@elitealtcoin.com</p>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        fullWidth
                        id="filled-basic"
                        name="email"
                        label="From"
                        variant="outlined"
                    />
                    <TextField
                        style={{ marginTop: 16 }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        fullWidth
                        id="filled-basic"
                        label="Message"
                        name="message"
                        variant="outlined"
                        multiline
                        rows={4}
                    />
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            className="ContactUsSubmitButton"
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
                <Snackbars open={successopen} text="Email sent successfully!" severity="success" duration={3000} close={handleClose} />

            </div>
        </>
    )
}

export default ContactUsFields