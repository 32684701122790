import React from 'react'
import './styles.scss'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Modale from '../modal';

const SideBar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const loggedinPage = [
        {
            id: 1,
            name: 'deposit',
            link: '/deposit'
        },
        {
            id: 2,
            name: 'withdraw',
            link: '/withdraw'
        },
        {
            id: 3,
            name: 'market',
            link: '/markets'
        },
        {
            id: 4,
            name: 'transactions',
            link: '/transactions'
        },
        {
            id: 5,
            name: 'my profile',
            link: '/profile'
        },

    ]

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <div className='sideBarWrapper'>
            {loggedinPage.map((page) => (
                <>
                    <Link to={page.link} style={{ textDecoration: 'none' }} >
                        <Button
                            key={page.id}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {page.name}
                        </Button>
                    </Link>

                </>
            ))}
            <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={handleOpenModal}
            >
                Contact us
            </Button>
            <Modale handleCloseModal={handleCloseModal} openModal={openModal} />

        </div>
    )
}

export default SideBar