import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import Cookies from 'universal-cookie';
import SocialMedia from './socialMedia'

function Footer() {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <Container>
                <Grid container fluid >

                    <Grid lg={12} md={12} sm={12} xs={12} >
                        <div className={cookie.get('token') ? null : 'footerSectionsContainer'}>
                            <div className='footerSectionTwoContainer'>
                                <div className='footerSectionTwoLinks'>
                                    {/* <SocialMedia /> */} Social Media
                                </div>

                                <div className='footerSectionTwoLinks'>
                                    <div>
                                        <p>© Copy Right-2022 All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Footer
