import { Box, Button, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { login } from '../../api'

function SignupFields({ setErrormessage }) {
    const cookie = new Cookies()
    let navigate = useNavigate();


    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        cookie.set('wallet', response.data.wallet, { path: '/' })
                        cookie.set('balance', response.data.balance, { path: '/' })
                        cookie.set('currency', response.data.currency, { path: '/' })
                        navigate('/');
                        window.location.reload()
                    } else {
                        // console.log(response.message)
                        setErrormessage('user not found')
                    }
                })
            }

        }
    })


    return (
        <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box className='inputUsernameAndPassword' >
                <TextField
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    fullWidth
                    id="username-required"
                    label="Username"
                    variant="outlined"
                    margin="none"
                    name="username"
                // inputProps={{ style: { color: 'white' } }}
                />
            </Box>
            <Box className='inputUsernameAndPassword'>
                <TextField
                    className='FieldStyle'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    type="password"
                    fullWidth
                    id="password-required"
                    label="Password"
                    variant="outlined"
                    margin="none"
                    name="password"
                // inputProps={{ style: { borderRadius: '15px'} }}

                />
            </Box>

            <div className="divSubmitButton">
                <Button
                    type="submit"
                    variant="contained"
                    className='submitButton'
                >
                    Sign in
                </Button>
            </div>

        </form>
    )
}

export default SignupFields