import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import project from '../../assets/images/innovation.png'
import mission from '../../assets/images/target.png'
import vision from '../../assets/images/setting.png'


function Features() {

    return (
        <>
            <div className='featuresWrapper'>
                <Container>
                    <Grid container fluid >
                        <Grid lg={12} md={12} sm={12} xs={12} >
                            <div className='featuresTitleContainer'>
                                <div className='featuresTitle'>
                                    <p>Services</p>
                                </div>
                                <div className='featuresSubTitle'>
                                    <p>We provide exclusive services for our clients</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                            <div className='featuresContainer'>

                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <img src={project} width={100} height={100} alt="projectImage" />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Our Project</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>Is a reward and loyalty solution built using blockchain and smart contract technonlogy.</p>
                                    </div>

                                </div>

                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <img src={mission} width={100} height={100} alt="missionImage" />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Our Mission</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>We believe everyine should have access to and be part of the biggest transfer of wealth happening in the crypto space.</p>
                                    </div>
                                </div>

                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <img src={vision} width={100} height={100} alt="visionImage" />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Our Vision</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>Empowering people to "Act" today fot their future.</p>
                                    </div>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </>
    )
}

export default Features



// {
//     features.map((item) => (
//         <>
//             <Grid lg={4} md={6} sm={6} xs={12} className="featuresWrapper" >
//                 <div className='featuresContainer'>
//                     <p className='featureTitle'>{item.title}</p>
//                     <p className='featureSubTitle'>{item.subTitle}</p>
//                 </div>
//             </Grid>
//         </>
//     ))
// }
// <Grid lg={12} md={12} sm={12} xs={12}>
//     <div className='featuresContainerTwo'>
//         <p className='featuresTitleTwo'>Take control of your future with <br></br>superior technology and security</p>
//     </div>
// </Grid>
// {
//     features2.map((item) => (
//         <Grid lg={4} md={4} sm={4} xs={12}>
//             <div className='technologyContainer'>
//                 <img alt="featureImage" src={item.img} style={{ width: 100, aspectRatio: '1' }} />
//                 <h3 className='technologyTitle'>{item.title}</h3>
//                 <p className='technologySubTitle'>{item.subTitle}</p>
//             </div>
//         </Grid>
//     ))
// }