import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import Cookies from 'universal-cookie';
import cryptoBackground from '../../assets/images/343124-PAL56V-597.jpg'
import LoggedInPageList from '../../components/loggedinpagelist';
import useViewport from '../../hooks/useViewport';


function Body({ coins }) {
    const cookie = new Cookies()
    const { width } = useViewport();


    return (
        <>
            <div className="bodyWrapper" >
                <Container style={{ padding: 0 }}>
                    <Grid container fluid >
                        {
                            cookie.get('token') && width > 900 ?
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: 0 }}>
                                    <LoggedInPageList />
                                </Grid>
                                :
                                null
                        }

                        <Grid item lg={6} md={6} sm={12} xs={12} style={{ padding: 0 }}>
                            <div className='bodyContainer'>
                                <p className='bodyTitle'>Accumulate Crypto Rewards Today</p>
                                <p className='bodyDescription'>EliteAltCoin is a blockchain loyality platform and ecosystem that is designed to leverage existing payment infrastructure</p>
                                {
                                    cookie.get('token') ?
                                        null
                                        :
                                        <div className='buttonsContainer'>
                                            <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
                                                <Button
                                                    variant="contained"
                                                    className='bodyButton'
                                                >
                                                    Sign In
                                                </Button>
                                            </Link>
                                            <Link to="/signup" style={{ textDecoration: 'none', color: 'white', marginLeft: 8 }}>
                                                <Button
                                                    variant="contained"
                                                    className='bodyButton'
                                                >
                                                    Sign Up
                                                </Button>
                                            </Link>

                                        </div>
                                }
                            </div>
                        </Grid>

                        <Grid lg={6} md={6} sm={12} xs={12}>
                            <div className='cryptoBackgroundContainer'>
                                <img src={cryptoBackground} className='cryptoBackground' alt="cryptoBackgroundImage" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </>
    )
}

export default Body