import { Container, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import cryptocurrency from '../../assets/images/cryptocurrency-image.png'


function MobileApp() {

    return (
        <>
            <div className='cryptocurrencyWrapper'>
                <Container >
                    <Grid container fluid >
                        <Grid lg={7} md={7} sm={12} xs={12} >
                            <div className='cryptocurrencyImageContainer'>
                                <img src={cryptocurrency} alt="cryptoImage" />
                            </div>
                        </Grid>
                        <Grid lg={5} md={5} sm={12} xs={12}>
                            <div className='paragraphContainer'>
                                <div className='paragraphTitle'>
                                    <h1>How Our Program Works</h1>
                                </div>
                                <div className='paragraphBody'>
                                    <p>
                                        We have acquired licencing rights to use an exclusive arbitrage trading system
                                        currently accessible only to accredited high net worth individuals.
                                        We will be leveraging the power of the Artificial Intelligence Trading Bot for the
                                        company to earn funds to expand our market reach, re-invest in research and development
                                        and to acquire customers for our ecosystem.
                                        As our customer base grows, we will have a greater attraction to bring on larger merchants and
                                        as our merchant database increases.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default MobileApp