import { Container, Grid } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getNews } from '../../api'
import './styles.css'

function Blogs() {
    const [news, setNews] = useState([]);
    useEffect(
        () => {
            getNews().then(res => {
                setNews(res.items)
            }).catch(error => console.log(error))
        }, [])

    return (
        <>
            <div className="blogWrapper" >
                <Container>
                    <Grid container fluid >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="blogTitle">
                            <p>Blogs</p>
                        </Grid>
                        <Grid className='scrollBlogContainer' container fluid >
                            {
                                news.map((item, i) => (
                                    <Grid xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                                        <div className='blogContainer' >
                                            <div className="blogImageFloatingContainer">
                                                <img alt="blogImage" src={item.media.thumbnail.url} className="blogImage" />
                                                <div className='blogFloatingContainer'>
                                                    <p style={{ fontWeight: 'bolder' }}>{moment(item.published).format('L')}</p>
                                                    <p>{item.author}</p>
                                                </div>
                                            </div>
                                            <h3 style={{ marginLeft: 8 }}>{item.title}</h3>
                                            <div className='footerNewsContainer'>
                                                <a href={item.link} >Read more</a>
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Blogs