import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'

function CryptoCurrency({ coins }) {

    return (
        <>
            <div className='cryptoWrapper'>
                <Container>
                    <Grid container fluid >
                        {
                            coins.slice(0, 4).map((item) => (
                                <Grid lg={3} md={3} sm={6} xs={12} className="cryptoDetailWrapper" key={item?.id} >
                                    <div className='cryptoDetailContainer'>
                                        <div
                                            className='cryptoLogo'
                                            style={{ backgroundImage: `url(${item?.image})` }}
                                        >
                                        </div>
                                        <div>
                                            <p className='cryptoName'>{item?.name}/<span>{item?.symbol}</span></p>
                                        </div>
                                        <div>
                                            <p
                                                className={item?.price_change_percentage_24h < 0 ? 'negChange' : 'posChange'}
                                            >
                                                {item?.price_change_percentage_24h}%
                                            </p>
                                        </div>
                                        <div>
                                            <p className='cryptoCurrency'>Price: {item?.current_price} $</p>
                                        </div>
                                        <div>
                                            <p className='volumeValue'>Volume: {item?.total_volume}$</p>
                                        </div>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default CryptoCurrency